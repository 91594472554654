
import { useRoute, useRouter } from 'vue-router'
import api, { useApi } from '@/api'
import { computed, defineComponent, ref } from 'vue'
import { CreateTeamDto, TeamDto, UserDto } from '../../service/__generated-api'
import UserInTable from '@/components/user-in-table.vue'
import { normalizeArray } from '@/utils'

export default defineComponent({
  components: { UserInTable },
  setup() {
    const errorMessage = ref<string>()

    const route = useRoute()
    const router = useRouter()
    let team = ref<TeamDto>({
      id: 0,
      name: '',
      users: []
    })

    const isLoading = ref(false)
    if (route.params.id) {
      isLoading.value = true
      api.teams
        .getTeam(Number(route.params.id))
        .then(({ data }) => {
          team.value = data
        })
        .catch(e => {
          const message = e.response?.data?.message
          errorMessage.value = normalizeArray(message)[0]
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const usersRes = useApi(api => api.users.getAll())
    const users = computed<UserDto[]>(
      () =>
        usersRes.data.value?.filter(
          user => !team.value.users.some(({ id }) => id === user.id)
        ) ?? []
    )

    const selectedUser = ref<number>()
    function addUser() {
      if (!selectedUser.value) return
      const user = getUser(selectedUser.value)
      if (!user) return
      team.value.users.push(user)
      selectedUser.value = undefined
    }
    function getUser(userId: number) {
      return usersRes.data.value?.find(user => user.id === userId)
    }
    function removeUser(userId: number) {
      team.value.users = team.value.users.filter(users => users.id !== userId)
    }

    function onSubmit() {
      const payload: CreateTeamDto = {
        name: team.value.name,
        users: team.value.users.map(user => user.id)
      }

      const request = route.params.id
        ? api.teams.updateTeam(Number(route.params.id), payload)
        : api.teams.createTeam(payload)
      request
        .then(() => {
          router.push('/teams')
        })
        .catch(e => {
          const message = e.response?.data?.message
          errorMessage.value = normalizeArray(message)[0]
        })
    }

    return {
      isLoading,
      errorMessage,
      team,
      users,
      selectedUser,
      addUser,
      removeUser,
      onSubmit
    }
  }
})
