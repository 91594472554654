<template>
  <div class="container mt-6">
    <form @submit.prevent="onSubmit" class="section">
      <div class="columns">
        <div class="column">
          <h1 class="title">{{ $route.params.id ? 'Edit' : 'Add' }} Team</h1>
        </div>
        <div class="column is-narrow">
          <button type="submit" class="button is-dark">Save</button>
        </div>
      </div>
      <hr />

      <div v-if="isLoading">
        <span class="icon-text">
          <span class="icon">
            <i class="fas fa-spin fa-sync-alt"></i>
          </span>
          <span>Loading...</span>
        </span>
      </div>
      <div v-else>
        <div
          class="has-text-danger"
          v-if="errorMessage"
          v-text="errorMessage"
        />
        <div class="label mt-4">Name*</div>
        <input
          type="text"
          class="input"
          placeholder="e.g. Panowie Programiści"
          v-model="team.name"
        />

        <div class="label mt-4">Users</div>
        <table class="table is-bordered is-fullwidth is-narrow mt-4">
          <tbody>
            <tr v-if="!team.users.length">
              <td>No users</td>
            </tr>
            <tr v-for="user in team.users" :key="user.id">
              <user-in-table :user="user" class="is-vcentered" />
              <td class="is-narrow p-0">
                <button
                  type="button"
                  class="button is-danger is-inverted"
                  @click="removeUser(user.id)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div class="select">
            <select v-model="selectedUser">
              <option selected :value="undefined">Select user</option>
              <option v-for="user in users" :value="user.id" :key="user.id">
                {{ user.firstName }} {{ user.lastName }}
              </option>
            </select>
          </div>

          <button
            type="button"
            class="button is-dark ml-4"
            :disabled="!selectedUser"
            @click="addUser"
          >
            Add user
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { useRoute, useRouter } from 'vue-router'
import api, { useApi } from '@/api'
import { computed, defineComponent, ref } from 'vue'
import { CreateTeamDto, TeamDto, UserDto } from '../../service/__generated-api'
import UserInTable from '@/components/user-in-table.vue'
import { normalizeArray } from '@/utils'

export default defineComponent({
  components: { UserInTable },
  setup() {
    const errorMessage = ref<string>()

    const route = useRoute()
    const router = useRouter()
    let team = ref<TeamDto>({
      id: 0,
      name: '',
      users: []
    })

    const isLoading = ref(false)
    if (route.params.id) {
      isLoading.value = true
      api.teams
        .getTeam(Number(route.params.id))
        .then(({ data }) => {
          team.value = data
        })
        .catch(e => {
          const message = e.response?.data?.message
          errorMessage.value = normalizeArray(message)[0]
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const usersRes = useApi(api => api.users.getAll())
    const users = computed<UserDto[]>(
      () =>
        usersRes.data.value?.filter(
          user => !team.value.users.some(({ id }) => id === user.id)
        ) ?? []
    )

    const selectedUser = ref<number>()
    function addUser() {
      if (!selectedUser.value) return
      const user = getUser(selectedUser.value)
      if (!user) return
      team.value.users.push(user)
      selectedUser.value = undefined
    }
    function getUser(userId: number) {
      return usersRes.data.value?.find(user => user.id === userId)
    }
    function removeUser(userId: number) {
      team.value.users = team.value.users.filter(users => users.id !== userId)
    }

    function onSubmit() {
      const payload: CreateTeamDto = {
        name: team.value.name,
        users: team.value.users.map(user => user.id)
      }

      const request = route.params.id
        ? api.teams.updateTeam(Number(route.params.id), payload)
        : api.teams.createTeam(payload)
      request
        .then(() => {
          router.push('/teams')
        })
        .catch(e => {
          const message = e.response?.data?.message
          errorMessage.value = normalizeArray(message)[0]
        })
    }

    return {
      isLoading,
      errorMessage,
      team,
      users,
      selectedUser,
      addUser,
      removeUser,
      onSubmit
    }
  }
})
</script>
